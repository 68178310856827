




































import {defineComponent} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {VTextField} from 'vuetify/lib/components';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';

export default defineComponent({
  name: 'uOttawa1321Lab5Question1',
  components: {
    FileUploadUiComponent,
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    STextarea,
    VTextField,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      stereoisomerScheme: [] as File[],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.stereoisomerScheme];
    },
    fileUploadLabel(): string {
      return this.inputs.language === 'en'
        ? 'Click here to upload your file'
        : 'Cliquez ici pour télécharger votre fichier.';
    },
  },
});
